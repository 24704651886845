<template>
  <b-row>
    <b-col xl="12" lg="12" md="12" sm="12">
      <b-card no-body class="card-statistics">
        <b-card-header>
          <b-card-title>Rekap Status Ajuan Komisi ({{ tipekomisi.toUpperCase() }})</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <b-button v-b-tooltip.hover.top="'Klik disini untuk filter tanggal dan tipe komisi'" variant="link"
              id="popover-reactive-1" ref="button">
              Filter
            </b-button><b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Ajuan Komisi'"
              @click.prevent="$router.push(`/finance/komisi`)">
              <feather-icon icon="ExternalLinkIcon" size="18" class="cursor-pointer" />
            </b-link>
            <b-popover target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="bottomleft"
              ref="popover" @show="onShow" @shown="onShown" @hidden="onHidden">
              <template #title>
                Filter
              </template>

              <div>
                <b-form-group label="Tanggal Mulai" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-datepicker
                    :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }" size="sm"
                    v-model="tanggal_mulai"></b-form-datepicker>
                  <!-- <b-form-input ref="input1" id="popover-input-1" v-model="input1" :state="input1state"
                        size="sm"></b-form-input> -->
                </b-form-group>

                <b-form-group label="Tanggal Akhir" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-datepicker
                    :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }" size="sm"
                    v-model="tanggal_selesai"></b-form-datepicker>
                  <!-- <b-form-select id="popover-input-2" v-model="input2" :state="input2state" :options="options"
                        size="sm"></b-form-select> -->
                </b-form-group>

                <b-form-group label="Tipe" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-select v-model="tipekomisi" :options="options" @input="getRekapKomisi()"
                    size="sm"></b-form-select>
                </b-form-group>

                <!-- <b-alert show class="small">
                      <strong>Current Values:</strong><br>
                      Name: <strong>{{ input1 }}</strong><br>
                      Color: <strong>{{ input2 }}</strong>
                    </b-alert> -->

                <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
                <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
              </div>
            </b-popover>
            <!-- <b-dropdown id="dropdown-grouped" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="link" right
              class="dropdown-icon-wrapper">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item @click.prevent="$router.push(`/finance/komisi`)">
                Detail
              </b-dropdown-item>
              <b-dropdown-item>a
              </b-dropdown-item>
            </b-dropdown> -->
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col md="3" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="secondary">
                    <feather-icon size="24" icon="CircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ itemsrekap ? itemsrekap.pending : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Pending
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="warning">
                    <feather-icon size="24" icon="LoaderIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ itemsrekap ? itemsrekap.proses : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Proses
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="success">
                    <feather-icon size="24" icon="CheckCircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ itemsrekap ? itemsrekap.selesai : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Selesai
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3" sm="6" class="mb-md-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="danger">
                    <feather-icon size="24" icon="XCircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ itemsrekap ? itemsrekap.dibatalkan : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Batal
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col xl="12" lg="12" md="12" sm="12">
      <b-card-actions action-collapse title="Daftar Ajuan Komisi">
        <b-overlay :show="loading">
          <b-row>
            <b-col xl="3" lg="3" md="3" sm="12">
              <!-- <b-card-actions action-collapse :title="'Komisi (' + this.cek.length + ')'" border-variant="primary"> -->
              <!-- <h5>Komisi ({{ this.cek.length == 0 ? null : this.cek.length}})</h5> -->
              <h5>Total Komisi Rp. {{ formatRupiah(totalKomisi) }}</h5>
              <template v-if="cek.length >= 1 && selectedStatus == 'pending'">
                <b-button class="mb-1" block @click="approveajuanproses()" variant="primary">Approve Proses ({{
                  this.cek.length
                  == 0 ? null : this.cek.length }})</b-button>
                <!-- <b-button class="mb-1" block @click="approveajuan()" variant="primary">Approve ({{ this.cek.length == 0 ? null : this.cek.length}})</b-button> -->
              </template>
              <template v-else-if="cek.length >= 1 && selectedStatus == 'proses'">
                <b-button class="mb-1" block @click="approveajuan()" variant="primary">Approve Selesai ({{ this.cek.length
                  == 0 ? null : this.cek.length }})</b-button>
                <!-- <b-button class="mb-1" block @click="approveajuan()" variant="primary">Approve ({{ this.cek.length == 0 ? null : this.cek.length}})</b-button> -->
              </template>
              <template v-else-if="cek.length == 0">
                <b-button class="mb-1" block @click="approveajuan()" variant="secondary" disabled>Approve</b-button>
              </template>
              <b-modal hide-backdrop no-close-on-esc no-close-on-backdrop v-model="showKonfirmasi" id="modal-konfirmasi"
                size="md" ok-variant="secondary" centered :title="'Approve Komisi'">
                <!-- <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-card> -->
                <validation-observer ref="vkonfirmasi">
                  <b-form>
                    <b-row>
                      <b-col sm="12" md="12">
                        <b-form-group label="Jenis Transaksi">
                          <b-form-select v-model="transactionType" :options="[
                            { value: 1, text: 'Debit' },
                            { value: 2, text: 'Kredit' },
                          ]" id="v-transaction_type" name="transaction_type" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="12" v-if="transactionType">
                        <b-form-group label="Pilih Akun">
                          <!-- label-for="v-id_akun" -->
                          <validation-provider #default="{ errors }" rules="required" name="id_akun">
                            <b-form-select v-model="formkonfirmasi.id_akun" :options="id_akun" id="v-id_akun"
                              name="id_akun" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="12">
                        <b-form-group label="Pilih Kas">
                          <!-- label-for="v-id_kas" -->
                          <validation-provider #default="{ errors }" rules="required" name="id_kas">
                            <b-form-select v-model="formkonfirmasi.id_kas" :options="id_kas" id="v-id_kas"
                              name="id_kas" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="12">
                        <b-form-group label="Pilih Status">
                          <!-- label-for="v-status" -->
                          <validation-provider #default="{ errors }" rules="required" name="status">
                            <b-form-select v-model="formkonfirmasi.statuskomisi" :options="statuskomisi" id="v-status"
                              name="status" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
                <b-button variant="primary" @click.prevent="selesaikan()" block> Konfirmasi </b-button>
                <!-- </b-card>
            </b-col>
          </b-row> -->
                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" variant="danger" @click="
                      showKonfirmasi = false;
                    getDataKomisi();
                    ">
                      Keluar
                    </b-button>
                  </section>
                </template>
              </b-modal>
              <!-- </b-card-actions> -->
            </b-col>
            <b-col xl="9" lg="9" md="9" sm="12">
              <!-- <b-card-actions action-collapse title="Filter " border-variant="primary"> -->
              <b-row>
                <b-col xl="2" lg="2" md="2" sm="2">
                  <b-form-group label="Per page" label-size="sm">
                    <!-- <label class="d-inline-block text-sm-top mr-50">Per page</label> -->
                    <b-form-select id="perPageSelect" v-model="perPage" :options="pageOptions" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col xl="4" lg="4" md="4" sm="4">
                  <b-form-group label="Sort" label-size="sm">
                    <b-input-group size="sm">
                      <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="6">
                  <b-form-group label="Filter" label-size="sm">
                    <b-input-group>
                      <b-form-input size="sm" v-model="filter" type="search" placeholder="Type to Search" />
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''" size="sm"> Clear </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col xl="4" lg="4" md="4" sm="4">
                  <b-form-group label="Tipe Komisi" label-size="sm">
                    <!-- <div class="d-flex align-items-center w-100"> -->
                    <!-- <label class="d-inline-block text-sm-top mr-50">Status Ajuan</label> -->
                    <b-form-select id="selectedjenis" v-model="selectedjenis" :options="jenisOptions" size="sm"
                      @input="getDataKomisi()" />
                    <!-- </div> -->
                  </b-form-group>
                </b-col>
                <b-col xl="4" lg="4" md="4" sm="4">
                  <b-form-group label="Status" label-size="sm">
                    <!-- <div class="d-flex align-items-center w-100"> -->
                    <!-- <label class="d-inline-block text-sm-top mr-50">Status Ajuan</label> -->
                    <b-form-select id="selectedStatus" v-model="selectedStatus" :options="statusOptions" size="sm" />
                  </b-form-group>
                </b-col>
                <b-col xl="4" lg="4" md="4" sm="4">
                  <b-form-group label="Bundle" label-size="sm">
                    <!-- <div class="d-flex align-items-center w-100"> -->
                    <!-- <label class="d-inline-block text-sm-top mr-50">Status Ajuan</label> -->
                    <b-form-select id="selectedjenis" v-model="is_bundled" :options="BundleOptions" size="sm"
                      @input="getDataKomisi()" />
                    <!-- </div> -->
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- </b-card-actions> -->
            </b-col>
            <b-col cols="12">
              <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fieldsFinance" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(urutan)="{ index }">
                  {{ index + 1 }}
                </template>
                <template #cell(cek)="{ item }">
                  <b-form-checkbox v-if="item.status == 'pending' || item.status == 'proses'" v-model="cek"
                    :value="item" />
                </template>
                <template #cell(diberikan)="data">
                  <b-badge :variant="iberi[1][data.value]">
                    {{ iberi[0][data.value] }}
                  </b-badge>
                </template>
                <template #cell(status)="{ item }">
                  <b-badge :variant="statuswarna[1][item.status]">
                    {{ statuswarna[0][item.status] }}
                  </b-badge>
                </template>
                <template #cell(type)="{ item }">
                  <b-badge :variant="typewarna[1][item.type]">
                    {{ typewarna[0][item.type] }}
                  </b-badge>
                </template>
                <template #cell(nominal)="{ item }">
                  {{ formatRupiah(item.nominal) }}
                </template>
                <template #cell(tanggal)="{ item }">
                  {{ humanDate(item.tanggal) }}
                </template>
                <template #cell(diterima)="data">
                  <b-badge :variant="iterima[1][data.value]">
                    {{ iterima[0][data.value] }}
                  </b-badge>
                </template>
                <template #cell(status2)="{ item }">
                  <b-badge :variant="getInfo(item).variant">
                    {{ getInfo(item).status }}
                  </b-badge>
                </template>
                <template #cell(actions)="{ item }">
                  <!-- <b-button v-if="row.item.tolak == 0" v-b-tooltip.hover.right="'Print / Cetak'" size="sm"
                    @click.prevent="unduhbop(row.item)" variant="outline-primary" class="mr-50">
                    <feather-icon icon="PrinterIcon"></feather-icon>
                  </b-button> -->
                  <!-- <b-button v-b-tooltip.hover.right="'Detail'" size="sm"
                    @click.prevent="$router.push(`/biaya-operasional/detail/${row.item.id}`)" class="mr-50"
                    variant="outline-success">
                    <feather-icon icon="EyeIcon" />
                  </b-button> -->
                  <!-- <b-button v-b-tooltip.hover.right="'Detail'" size="sm" class="mr-50" @click="approve(row.item)"
                    variant="outline-info">
                    <feather-icon icon="FilePlusIcon" />
                  </b-button> -->
                  <b-button v-b-tooltip.hover.right="'Selesaikan Ajuan Komisi'"
                    v-if="allowUpdate() && item.status == 'proses' && item.bundle_id == null" size="sm"
                    @click="approveajuan(item)" class="mr-50" variant="outline-success">
                    <feather-icon icon="CheckCircleIcon" />
                  </b-button>
                  <b-button v-b-tooltip.hover.right="'Batalkan Ajuan Komisi'"
                    v-if="allowUpdate() && (item.status == 'pending' || item.status == 'proses')" size="sm"
                    @click="batalkan(item)" class="mr-50" variant="outline-danger">
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                  <b-button v-if="allowDelete() && item.status == 'dibatalkan'"
                    v-b-tooltip.hover.right="'Hapus Ajuan Komisi'" size="sm" @click="remove(item)" class="mr-50"
                    variant="outline-danger">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
                <template #cell(show_detail)="row">
                  <b-form-checkbox 
                    v-model="row.detailsShowing" v-if="row.item.list_ajuan_komisi && row.item.list_ajuan_komisi.length > 0" plain class="vs-checkbox-con" @change="row.toggleDetails">
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                  </b-form-checkbox>
                  <span v-else class="text-danger">
                    <feather-icon icon="SlashIcon" />
                  </span>
                </template>
                <template #row-details="{ item, toggleDetails }">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col cols="12">

                        <h5>Daftar Rincian Ajuan</h5>
                        <b-table striped small hover responsive
                          :items="item.list_ajuan_komisi" :fields="fieldsFinanceD" >
                          <template #cell(urutan)="{ index }">
                            {{ index + 1 }}
                          </template>
                          <template #cell(cek)="{ item }">
                            <b-form-checkbox v-if="item.status == 'pending' || item.status == 'proses'" v-model="cek"
                              :value="item" />
                          </template>
                          <template #cell(diberikan)="data">
                            <b-badge :variant="iberi[1][data.value]">
                              {{ iberi[0][data.value] }}
                            </b-badge>
                          </template>
                          <template #cell(status)="{ item }">
                            <b-badge :variant="statuswarna[1][item.status]">
                              {{ statuswarna[0][item.status] }}
                            </b-badge>
                          </template>
                          <template #cell(type)="{ item }">
                            <b-badge :variant="typewarna[1][item.type]">
                              {{ typewarna[0][item.type] }}
                            </b-badge>
                          </template>
                          <template #cell(nominal)="{ item }">
                            {{ formatRupiah(item.nominal) }}
                          </template>
                          <template #cell(tanggal)="{ item }">
                            {{ humanDate(item.tanggal) }}
                          </template>
                          <template #cell(diterima)="data">
                            <b-badge :variant="iterima[1][data.value]">
                              {{ iterima[0][data.value] }}
                            </b-badge>
                          </template>
                          <template #cell(status2)="{ item }">
                            <b-badge :variant="getInfo(item).variant">
                              {{ getInfo(item).status }}
                            </b-badge>
                          </template>
                          <template #cell(actions)="{ item }">
                            <b-button v-b-tooltip.hover.right="'Selesaikan Ajuan Komisi'"
                              v-if="allowUpdate() && item.status == 'proses' && item.bundle_id == null" size="sm"
                              @click="approveajuan(item)" class="mr-50" variant="outline-success">
                              <feather-icon icon="CheckCircleIcon" />
                            </b-button>
                            <b-button v-b-tooltip.hover.right="'Batalkan Ajuan Komisi'"
                              v-if="allowUpdate() && (item.status == 'pending' || item.status == 'proses')" size="sm"
                              @click="batalkan(item)" class="mr-50" variant="outline-danger">
                              <feather-icon icon="XCircleIcon" />
                            </b-button>
                            <b-button v-if="allowDelete() && item.status == 'dibatalkan'"
                              v-b-tooltip.hover.right="'Hapus Ajuan Komisi'" size="sm" @click="remove(item)" class="mr-50"
                              variant="outline-danger">
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>

                    <b-button size="sm" variant="outline-secondary" @click="toggleDetails">
                      Hide
                    </b-button>
                  </b-card>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BLink,
  BPopover,
  BAlert,
  BCard,
  BCardGroup,
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BPopover,
    BAlert,
    BCard,
    BCardGroup,
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(val) {
      if (this.selectedStatus != 'pending' || this.selectedStatus == null) {
        this.is_bundled = 1; this.getDataKomisi()
      } else {
        this.is_bundled = null; this.getDataKomisi()
      }
    },
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    // selectedStatus(status) {
    //   if (status) {
    //     this.getData();
    //   }
    // },
  },
  data() {
    return {
      loading: false,
      statusOptions: [
        {
          value: null,
          text: "Semua",
          warna: "primary",
        },
        {
          value: 'pending',
          text: "Pending",
          warna: "info",
        },
        {
          value: 'proses',
          text: "Proses",
          warna: "warning",
        },
        {
          value: 'selesai',
          text: "Selesai",
          warna: "success",
        },
        {
          value: 'dibatalkan',
          text: "Batal",
          warna: "danger",
        },
      ],
      selectedStatus: 'pending',
      jenisOptions: [
        {
          value: null,
          text: "Semua",
        },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        {
          value: "bulan",
          text: "Komisi Bulanan",
        },
      ],
      is_bundled: null,
      BundleOptions: [
        {
          value: 1,
          text: "Ya",
        },
        {
          value: null,
          text: "Tidak",
        },
      ],
      selectedjenis: null,
      jenisOptions2: [
        // {
        //   value: null,
        //   text: "Semua",
        // },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        // {
        //   value: "bulan",
        //   text: "Komisi Bulanan",
        // },
      ],
      selectedjenis2: 'affiliate',
      showModal: false,
      showModalApprove: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_gudang: "",
        tanggal: new Date(),
        keterangan: "",
      },
      id_gudang: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          1: "Setuju",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      statuskomisi: [
        // {
        //   value: "pending",
        //   text: "Pending",
        // },
        {
          value: "proses",
          text: "Proses",
        },
        {
          value: "selesai",
          text: "Selesai",
        },
        {
          value: "dibatalkan",
          text: "Batal",
        },
      ],
      statuswarna: [
        {
          "pending": "Pending",
          "proses": "Proses",
          "selesai": "Selesai",
          "dibatalkan": "Batal",
          null: "Belum",
        },
        {
          "pending": "light-secondary",
          "proses": "warning",
          "selesai": "light-success",
          "dibatalkan": "light-danger",
          null: "light-info",
        },
      ],
      typewarna: [
        {
          "affiliate": "Affiliate",
          "cabang": "Cabang",
          null: "Belum",
        },
        {
          "affiliate": "light-info",
          "cabang": "light-success",
          null: "light-secondary",
        },
      ],
      iterima: [
        {
          1: "Terima",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      fields: [
        {
          key: "urutan",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        // { key: "diberikan", label: "Finance" },
        // { key: "diterima", label: "Status" },
        { key: "status2", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinance: null,
      fieldsFinanceP: [
        {
          key: "cek",
          label: "#",
        },
        {
          key: "urutan",
          label: "#",
        },
        // {
        //   key: "no",
        //   label: "No",
        // },
        { key: "created_at", label: "Tgl Ajuan", sortable: true },
        // { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "member.nama_lengkap", label: "Nama" },
        { key: "type", label: "type" },
        { key: "nominal", label: "Total (Rp)" },
        { key: "status", label: "Status" },
        { key: "rekening.nama", label: "rekening" },
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinanceB: [
        {
          key: "show_detail",
          label: "Rincian",
        },
        {
          key: "urutan",
          label: "No",
        },
        // {
        //   key: "no",
        //   label: "No",
        // },
        { key: "created_at", label: "Tgl Ajuan", sortable: true },
        // { key: "gudang.nama_gudang", label: "Gudang" },
        // { key: "member.nama_lengkap", label: "Nama" },
        // { key: "type", label: "type" },
        { key: "nominal", label: "Total (Rp)" },
        { key: "status", label: "Status" },
        // { key: "rekening.nama", label: "rekening" },
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinanceD: [
        // {
        //   key: "cek",
        //   label: "#",
        // },
        {
          key: "urutan",
          label: "#",
        },
        // {
        //   key: "no",
        //   label: "No",
        // },
        { key: "created_at", label: "Tgl Ajuan", sortable: true },
        // { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "member.nama_lengkap", label: "Nama" },
        { key: "type", label: "type" },
        { key: "nominal", label: "Total (Rp)" },
        { key: "status", label: "Status" },
        { key: "rekening.nama", label: "rekening" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      cek: [],
      showKonfirmasi: false,
      formkonfirmasi: {
        id: null,
        bukti_tf: {
          file: null,
        },
      },
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],

      itemsrekap: null,
      input1: '',
      input1state: null,
      input2: '',
      input2state: null,
      options: [{ text: '- Pilih -', value: '' }, { text: 'Affiliate', value: 'affiliate' }, { text: 'Cabang', value: 'cabang' }],
      input1Return: '',
      input2Return: '',
      popoverShow: false,
      tipekomisi: 'affiliate',
      tanggal_mulai: null,
      tanggal_selesai: null,
      id: null,
    };
  },
  computed: {

    totalKomisi() {
      if (this.cek == null) {
        return delete this.cek
      }
      return this.cek.reduce((nominal, item) => (nominal += parseInt(item.nominal)), 0);
    },
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsFinance
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // this.setDataSort()
    await this.getDataKomisi();

    this.getKas();
    this.getAkun();
    if (this.isFinance) {
      this.sortBy = ''
      this.sortDesc = true
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      this.getRekapKomisi()
      if (!this.input1) {
        this.input1state = false
      }
      if (!this.input2) {
        this.input2state = false
      }
      if (this.input1 && this.input2) {
        this.onClose()
        // Return our popover form results
        this.input1Return = this.input1
        this.input2Return = this.input2
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.input1 = ''
      this.input2 = ''
      this.input1state = null
      this.input2state = null
      this.input1Return = ''
      this.input2Return = ''
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.input1)
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          ; (ref.$el || ref).focus()
        })
      })
    },
    submitkonfirmasi() {
      this.$refs.vkonfirmasi.validate().then((success) => {
        if (success) {
          const payloadkonfirmasi = [];
          this.cek.forEach((konfir) => {
            let konfirmasi = {
              id: konfir.id,
              // status: this.formkonfirmasi.statuskomisi,
              akun_id: this.formkonfirmasi.id_akun,
              kas_id: this.formkonfirmasi.id_kas,
            };
            payloadkonfirmasi.push(konfirmasi);
          });

          let payload = {
            id: this.cek.id,
            // status: this.formkonfirmasi.statuskomisi,
            akun_id: this.formkonfirmasi.id_akun,
            kas_id: this.formkonfirmasi.id_kas,
          }
          this.$store
            .dispatch("ajuan/bundleselesai", payload)
            .then(() => {
              this.cek = null;
              this.showKonfirmasi = false;
              this.displaySuccess({
                text: "Berhasil diproses",
              });
              this.getDataKomisi();
              // this.formKonfirmasi = null
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        };
      }
      )
    },
    approveajuan(item) {
      this.id = item.id
      this.showKonfirmasi = true
    },
    approveajuanproses() {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan diproses",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const payloadkonfirmasi = [];
          this.cek.forEach((konfir) => {
            let konfirmasi = {
              id: konfir.id,
              // status: this.formkonfirmasi.statuskomisi,
              // akun_id: this.formkonfirmasi.id_akun,
              // kas_id: this.formkonfirmasi.id_kas,
            };
            payloadkonfirmasi.push(konfirmasi);
          })
          this.$store
            .dispatch("ajuan/bundleproses", payloadkonfirmasi)
            .then(() => {
              this.cek = null
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil diproses",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda tidak berubah :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    async unduhbop(item) {
      this.loading = true
      const response = await this.$store.dispatch("ajuan/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    async getStatus() {
      const currentMenu = await this.currentMenu();
      const params = {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      };
      const statusAjuan = await this.$store.dispatch("statusrole/getData", params);
      statusAjuan.map((status) => {
        this.statusOptions.push({
          value: status.data_status,
          text: status.nama_status,
        });
      });
    },
    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-komisi/download-attachment/${ajuan_id}`, "_blank");
    // },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      switch (status) {
        case null:
        case 1:
          result = {
            color: "danger",
            text: "PENDING",
          };
          break;
        case 2:
          result = {
            color: "info",
            text: "DISETUJUI SPV",
          };
          break;
        case 3:
          result = {
            color: "dark",
            text: "DISETUJUI GUDANG",
          };
          break;
        case 4:
          result = {
            color: "success",
            text: "SELESAI",
          };
          break;
      }

      return result;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    approve(item) {
      this.form = item;
      this.showModalApprove = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ajuan/hapus", item)
            .then(() => {
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda aman :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    batalkan(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan dibatalkan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ajuan/batal", item)
            .then(() => {
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil dibatalkan",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda aman :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    selesaikan() {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan komisi ini akan diselesaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let payload = {
            id: this.id,
            akun_id: this.formkonfirmasi.id_akun,
            kas_id: this.formkonfirmasi.id_kas,
          }
          console.log('AJI', payload)
          this.$store
            .dispatch("ajuan/bundleselesai", payload)
            .then(() => {
              this.id = null
              this.showKonfirmasi = false
              this.getDataKomisi();
              this.displaySuccess({
                message: "Ajuan berhasil diselesaikan",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else {
          this.$swal({
            title: 'Batal',
            text: 'Data anda aman :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      if (!this.isAdminGudang) {
        this.form.id_gudang = null;
      }
    },
    submit() {
      this.$refs.formajuan.validate().then(async (success) => {
        if (success) {
          if (this.isAdminGudang) {
            this.form.id_gudang = this.user.karyawan.id;
          }
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_gudang = this.myGudang.id;
          if (!this.form.status) {
            this.form.status = 1;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("ajuan/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/biaya-operasional/detail/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    getInfo(item) {
      const result = {
        variant: 'primary',
        kode: 10,
        status: '-'
      }
      if (item.selesai == 0) {
        result.variant = 'light-primary'
        result.kode = 1
        result.status = 'Belum Selesai'
      }
      if (item.selesai == 1 && item.tolak == 0 && item.id_akun == null) {
        result.variant = 'light-danger'
        result.kode = 2
        result.status = 'Pending'
      }
      else if ((item.selesai == 1 || item.selesai == 0) && item.tolak == 1) {
        result.variant = 'dark'
        result.kode = 3
        result.status = 'Ditolak Finance'
      }
      else if (item.selesai == 1 && item.tolak == 0 && item.id_akun > 0 && item.diterima == null) {
        result.variant = 'light-success'
        result.kode = 4
        result.status = 'Disetujui Finance'
      }
      else if (item.selesai == 1 && item.tolak == 0 && item.id_akun > 0 && item.diterima > 0) {
        result.variant = 'success'
        result.kode = 5
        result.status = 'Diterima Gudang'
      }

      return result
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      let params = this.isAdminGudang ? { id_gudang: this.myGudang.id, order: "desc" } : { order: "desc" };

      this.loading = true;
      const ajuans = await this.$store.dispatch("ajuan/getData", params);
      ajuans.map(ajuan => ajuan.info = this.getInfo(ajuan))
      const belumAjuan = ajuans.filter(ajuan => ajuan.info.kode == 1)
      const pendingAjuan = ajuans.filter(ajuan => ajuan.info.kode == 2)
      const tolakAjuan = ajuans.filter(ajuan => ajuan.info.kode == 3)
      const completeFinanceAjuan = ajuans.filter(ajuan => ajuan.info.kode == 4)
      const completeAjuan = ajuans.filter(ajuan => ajuan.info.kode == 5)
      if (this.isFinance) {
        this.items = [...pendingAjuan, ...completeFinanceAjuan, ...completeAjuan, ...tolakAjuan, ...belumAjuan]
      }
      if (this.isAdminGudang) {
        this.items = [...belumAjuan, ...pendingAjuan, ...completeFinanceAjuan, ...completeAjuan, ...tolakAjuan]
      }
      this.loading = false;
      // this.items = ajuans;
      this.totalRows = ajuans.length;
    },
    getid_gudang() {
      this.$store
        .dispatch("karyawan/getData", { jabatan_id: 5 })
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.id_gudang = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      return data.sales ? data.sales.nama_lengkap : "-";
    },
    getDataKomisi() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        // filter_by: "nama_lengkap",
        type: this.selectedjenis != null ? this.selectedjenis : null,
        // is_bundled: this.selectedjenis != 'pending' ? 1 : null,
        status: this.selectedStatus != null ? this.selectedStatus : null,
        is_bundled: this.is_bundled != null ? this.is_bundled : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("ajuan/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.ajuan.datas));
        let items_total = this.$store.state.ajuan.totals;
        this.items = items;
        this.totalRows = items_total;
        this.getRekapKomisi()
        if (this.selectedStatus == 'pending' && this.is_bundled == null) {
          this.fieldsFinance = this.fieldsFinanceP
        } else {
          this.fieldsFinance = this.fieldsFinanceB
        }
        this.loading = false;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getRekapKomisi() {
      this.loading = true;
      const payload = {
        type: this.tipekomisi != null ? this.tipekomisi : null,
        tanggal_mulai: this.tanggal_mulai != null ? this.tanggal_mulai : null,
        tanggal_selesai: this.tanggal_selesai != null ? this.tanggal_selesai : null,
      };
      this.$store.dispatch("ajuanrekap/getRekapStatus", payload).then((res) => {
        let items = JSON.parse(JSON.stringify(this.$store.state.ajuanrekap.datas2));
        // let items_total = this.$store.state.ajuanrekap.totals2;
        this.itemsrekap = items;
        // this.totalRowsre = items_total;
        this.loading = false;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          hem.map((item) => {
            item.value = item.id;
            item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) => [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
          this.kreditAkun = akuns.filter((akun) => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
  },
};
</script>
